<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('video.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('video.head_list') }} </v-card-title>
        <v-card-actions class="pb-4 d-flex align-end">
          <v-spacer />
          <v-btn color="primary" class="px-5" @click="$router.push('/video/video_form_create')">
            <v-icon class="px-3">mdi-plus</v-icon>
            <span class="pr-3">{{ $t('video.create') }} </span>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="itemsData"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.enable`]="{ item }">
              <span v-if="item.enable" class="success--text">{{ $t('open') }}</span>
              <span v-else class="error--text">{{ $t('close') }}</span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('video.manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$router.push(`/video/video_form_update/${item.id}`)">
                    <v-list-item-title>
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">{{ $t('edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="deleteVideo(item)">
                    <v-list-item-title>
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t('delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date: null,
      currentMemberDetail: {},
      search: '',
      loading: false,
      headers: [
        {
          text: this.$t('video.header1'),
          value: 'title',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('video.header2'),
          value: 'enable',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('video.header3'),
          value: 'created_at',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('video.header4'),
          value: 'actions',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    // await this.getNews(this.pagination.page, this.pagination.rowsPerPage)
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.$store.dispatch('getVideoList', `page=${newValue.page}&row=${newValue.itemsPerPage}`)
          this.itemsData = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'วิดีโอ',
            url: window.location.href,
            detail: 'ระบบจัดการวิดีโอ',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getVideo(page, row) {
      try {
        const result = await this.$store.dispatch('getVideoList', `page=${page}&row=${row}`)
        this.itemsData = result.data
        this.pagination.totalItems = result.count
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async deleteVideo(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('alert_list.list11')} ${item.title}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('delete'),
        })
        .then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const deletei = await this.$store.dispatch('deleteVideo', item.id)

            this.$swal.fire(this.$t('alert_list.list12'), '', 'success')
            const result = await this.getVideo(this.pagination.page, this.pagination.itemsPerPage)
            //  this.pagination.totalItems = result.count
            this.value = result.data
            this.loading = false
          }
        })
    },
    async searchKeyword(value) {
      this.loading = true
      try {
        let result = {}
        if (value) {
          result = await this.$store.dispatch('getVideoList', `page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
        } else {
          result = await this.$store.dispatch('getVideoList', `page=1&rows=${this.pagination.rowsPerPage}`)
        }
        this.pagination.totalItems = result.count
        this.value = result.data
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
      this.pagination.page = 1
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>
